<template>
  <a-layout class='index animated fadeIn'>
    <a-card :bordered='false'>
      <a-form ref="ruleForm" :model="form" :form="form" :rules="rules" class='' :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-item ref="appid" label="AppID" name="appid">
          <a-row :gutter="16">
            <a-col :span="12">
              <a-input v-model:value='form.appid' placeholder='请输入AppID' />
            </a-col>
            <a-col :span="12">
              <p>（应用ID）</p>
            </a-col>
          </a-row>
        </a-form-item>
        <a-form-item ref="app_secret" label="AppSecret" name="app_secret">
          <a-row :gutter="16">
            <a-col :span="12">
              <a-input v-model:value='form.app_secret' placeholder='请输入AppSecret' />
            </a-col>
            <a-col :span="12">
              <p>（应用密钥）</p>
            </a-col>
          </a-row>
        </a-form-item>
        <a-form-item ref="mchid" label="MCHID" name="mchid">
          <a-row :gutter="16">
            <a-col :span="12">
              <a-input v-model:value='form.mchid' placeholder='请输入MCHID' />
            </a-col>
            <a-col :span="12">
              <p>（商户号，申请微信支付的邮件中获取）</p>
            </a-col>
          </a-row>
        </a-form-item>
        <a-form-item ref="key" label="Key" name="key">
          <a-row :gutter="16">
            <a-col :span="12">
              <a-input v-model:value='form.key' placeholder='请输入key' />
            </a-col>
            <a-col :span="12">
              <p>（32位的API密钥）</p>
            </a-col>
          </a-row>
        </a-form-item>
        <a-form-item ref="ssl_cert" label="ssl_cert" name="ssl_cert">
          <a-row :gutter="16">
            <a-col :span="20">
              <a-textarea auto-size v-model:value='form.ssl_cert' placeholder='请输入ssl_cert' />
            </a-col>
          </a-row>
        </a-form-item>
        <a-form-item ref="ssl_key" label="ssl_key" name="ssl_key">
          <a-row :gutter="16">
            <a-col :span="20">
              <a-textarea auto-size v-model:value='form.ssl_key' placeholder='请输入ssl_key' />
            </a-col>
          </a-row>
        </a-form-item>
        <a-form-item ref="mode" label="授权模式" name="mode">
          <a-radio-group v-model:value="form.mode">
            <a-radio :value="1">公众号</a-radio>
            <a-radio :value="2">开放平台</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item ref="status" label="是否启用" name="status">
          <a-radio-group v-model:value="form.status">
            <a-radio :value="1">开启</a-radio>
            <a-radio :value="0">关闭</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 8, offset: 3 }" class='m-t-90'>
          <a-button type="primary" @click="onSubmit">
            保存
          </a-button>
        </a-form-item>
      </a-form>
    </a-card>
  </a-layout>
</template>

<script>
import { ref, onMounted, reactive, toRefs, inject } from "vue";
import { message } from "ant-design-vue";
import { iscode } from "@/utils/app";
import { paymentUpadte, paymentDetail } from "@/api/system";
let defForm = {
  appid: "",
  mode: "1",
  app_secret: "",
  mchid: "",
  key: "",
  id: "",
  status: 0,
  ssl_key: "",
  ssl_cert: "",
};
export default {
  setup(props, ctx) {
    const form = ref({ ...defForm });
    const state = reactive({
      labelCol: { span: 3 },
      wrapperCol: { span: 16 },
    });
    const $route = inject("$route");
    const $router = inject("$router");
    const _lodash = inject("_lodash");
    // 获取配置
    const getConfig = async () => {
      const hide = message.loading("正在加载中...", 0);
      let res = await paymentDetail().then((res) => res.data);
      if (res.code == 1) {
        setTimeout(hide);
        form.value =res.data;
        form.value.status = res.data.status;
      } else {
        setTimeout(hide);
        message.error(res.msg);
      }
    };
    const ruleForm = ref();
    //提交
    const onSubmit = () => {
      ruleForm.value
        .validate()
        .then(async () => {
          const hide = message.loading("正在加载中...", 0);
          let res = await paymentUpadte({ ...form.value }).then(
            (res) => res.data
          );
          if (res.code == 1) {
            setTimeout(hide);
            message.success(res.msg);
          } else {
            setTimeout(hide);
            message.error(res.msg);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
    onMounted(() => {
      getConfig();
    });
    return {
      form,
      ...toRefs(state),
      ruleForm,
      onSubmit,
    };
  },
};
</script>